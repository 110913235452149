.appt-book {
  .my-appointments {
    .appointment-container {
      .appointment-details {
        .location a {
          color: $color-black;
          word-break: break-all;
        }
      }
    }
  }
}

.sd-appt-booking {
  .elc-appt-booking_appointment-review-container {
    .elc-appt-booking_panel-box {
      .elc-appt-booking_panel-body {
        fieldset {
          border: none;
        }
      }
    }
  }
}


.appt-book {
  .appt-book-page-header {
    .appt-book-page-header-content {
      .inner h4 {
        width: 60%;
        @media #{$medium-up} {
          width: auto;
        }
      }
    }
  }
}
