.section-clinicalreality {
  .main {
    clear: both;
  }
  .mmuze-wrapper-btn {
    display: none;
  }
  .nav-promo {
    display: none;
  }
}

.clinical-reality {
  &__modules--recommended-products {
    .clinical-reality__recommended-products--carousel {
      top: 34%;
    }
  }
  &__ba--build {
    .ccr-flexslider .flex-viewport {
      max-height: 890px;
    }
  }
}
