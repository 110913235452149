@if $password_strength {
  .profile-password-update {
    &__action {
      .form-submit {
        &.disabled,
        &:disabled {
          border: 1px solid $color-light-gray;
          background-color: $color-white;
          color: $color-light-gray;
          cursor: not-allowed;
          pointer-events: none;
        }
      }
    }
  }
  .password-field {
    &__info {
      #signin & {
        @media #{$medium-up} {
          width: 95%;
          #{$ldirection}: 0;
          bottom: 50px;
        }
      }
      .checkout &,
      #confirm & {
        @media #{$medium-up} {
          position: absolute;
          #{$ldirection}: 202px;
          width: 200px;
          bottom: -39px;
          border: 1px solid $color-black;
        }
      }
      #password_reset & {
        @media #{$medium-up} {
          top: 0;
          width: auto;
          #{$ldirection}: 36%;
          -webkit-transform: translateY(-38px);
          -moz-transform: translateY(-38px);
          -o-transform: translateY(-38px);
          -ms-transform: translateY(-38px);
          transform: translateY(-38px);
        }
      }
      #confirm & {
        @media #{$medium-up} {
          #{$ldirection}: 218px;
          &:before {
            #{$ldirection}: -11px;
            top: 40%;
            -ms-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            transform: rotate(90deg);
          }
        }
      }
    }
    &__rules {
      li {
        color: $color-light-gray;
      }
      .checkout &,
      #confirm & {
        @media #{$medium-up} {
          column-count: 1;
        }
      }
    }
  }
  .change-password {
    fieldset.fs {
      overflow: visible;
    }
  }
}
