.sign-in,
.checkout {
  .social-login {
    .social-login__terms {
      @media #{$medium-up} {
        text-align: #{$ldirection};
        padding: 0;
      }
    }
  }
}

.checkout {
  .sign-in-panel {
    .social-login {
      .social-login__opt-in-checkbox {
        width: auto;
      }
      .social-login__email-opt-in-label {
        padding-#{$ldirection}: 10px;
        font-family: $base-font-family;
      }
      .social-login__email-opt-in {
        margin-#{$ldirection}: 12px;
        @media #{$medium-up} {
          display: flex;
          margin-#{$ldirection}: 0;
        }
      }
      .social-login__terms {
        margin-#{$ldirection}: 12px;
        @media #{$medium-up} {
          margin-#{$ldirection}: 0;
        }
        p {
          font-family: $base-font-family;
        }
      }
    }
  }
}

#signin {
  #signin-block {
    .social-login {
      .social-login__email-opt-in {
        input[type='checkbox'] {
          margin: 0;
          width: auto;
          height: auto;
        }
        label {
          margin-#{$ldirection}: 10px;
          font-family: $base-font-family;
          color: $color-btn-dark-gray;
          @media #{$medium-up} {
            margin-#{$ldirection}: 5px;
          }
        }
      }
    }
  }
}

.social-login.gnav {
  .social-login__button {
    padding-top: 10px;
    .facebook-logo {
      display: none;
    }
  }
}

.social-info {
  .social-info__connect {
    .fb-login-button {
      padding-#{$ldirection}: 5px;
    }
  }
}

.elc-user-state-logged-in {
  .social-login.gnav {
    display: none;
  }
}

.checkout-single-page {
  .panel > .column {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
/* Gnav */
.gnav-my-account-v1 {
  @media #{$large-only} {
    overflow-y: auto;
    height: 450px;
  }
  .social-login.gnav {
    .social-login__email-opt-in {
      margin-top: 15px;
      label {
        font-family: $base-font-family;
      }
    }
  }
}
/* Profile Page */
.registration_content {
  .social-info {
    display: inline-block;
  }
}
/* Confirmation Page */
#confirm {
  .checkout-confirmation-page {
    .social-login {
      border-top: 1px solid $color-light-grey;
      margin: 20px 0;
      .social-login__container {
        text-align: #{$ldirection};
        padding-top: 0;
        padding-#{$ldirection}: 15px;
        @media #{$medium-up} {
          padding-#{$ldirection}: 0;
        }
      }
      .social-login__title {
        font-size: 23px;
      }
      .social-login__email-opt-in {
        input[type='checkbox'] {
          width: auto;
          height: auto;
        }
        label {
          color: $color-btn-dark-gray;
          font-family: $base-font-family-roman;
          margin-top: 7px;
          @media #{$medium-up} {
            margin-top: 0;
          }
        }
        @media #{$medium-up} {
          display: flex;
        }
      }
    }
  }
}
