.elc-product-full {
  .elc-vto-foundation {
    .elc-vto {
      &-perfect-shade-label {
        font-family: $base-bolder-font-family;
      }
    }
    .elc-product-cta-wrapper {
      .elc-product-notify-me-button {
        font-size: 14px;
        height: 48px;
        line-height: normal;
      }
    }
  }
}
