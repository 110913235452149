.device-mobile {
  li {
    list-style-type: none;
  }
  .field-mobile-template {
    .basic-content-mobile-v1 {
      .content {
        height: auto;
        max-height: 100%;
        table {
          width: 100% !important;
          tr {
            td {
              width: 50%;
            }
          }
        }
      }
    }
  }
  #footer {
    .sticky-chat,
    .sticky-offer {
      z-index: 10;
    }
  }
}

.mobile_mpp {
  ul#mpp-product-grid {
    li.mpp-product {
      .product-info {
        p.price {
          span.mpp_price {
            display: block;
            width: 100%;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.contact_us {
  #contact_form_fields {
    .email_address1_container,
    .verify_email_address_container {
      input {
        width: 100%;
        padding: 4px 8px;
        border-radius: 0;
        border: 1px solid #dbdbdb;
      }
    }
  }
}

.offer-promo {
  .link-based-offer-ref-v1 {
    .link-based-offer-mobile-v1 {
      .subheading h2 {
        display: none;
      }
    }
  }
}

section {
  &.clinique-spinner &#game-win-box {
    .win-box-btns {
      display: block;
      .try-again-btn {
        margin-top: 15px;
      }
    }
  }
}

.form-submit {
  &.control-btns {
    height: 57px;
    margin-top: 15px;
    width: 100%;
  }
}
