#sms_promotion_container + * {
  clear: both;
}

.label_error {
  color: $color-red;
}

.checkout__offer-code__wallet-wrapper {
  .slick-track {
    .not_meet {
      opacity: 0.5;
    }
  }
}

body.loyalty_offer_signup {
  #colorbox.loyalty-popup-custom-alignment.horizontal-align--right {
    margin: 0 auto;
    #cboxWrapper {
      #cboxContent {
        width: 575px !important;
        @include breakpoint($ab-small-down) {
          height: 530px !important;
          width: 92% !important;
        }
      }
      .loyalty_popover__checkbox-label {
        float: none;
      }
      .loyalty_popover__sub-header {
        width: auto;
      }
    }
  }
}

.account-loyalty {
  .section-header {
    .smart-rewards-logo {
      margin-left: 0px;
    }
  }
  .section-content {
    .tier-point-msg {
      &.next-tier-points-msg {
        @include swap_direction(margin, 13px 10px 0 0);
        float: #{$rdirection};
        @media #{$large-up} {
          @include swap_direction(margin, 13px 0 0 50px);
          float: #{$ldirection};
        }
        span.remaining-points {
          margin-top: 8px;
        }
      }
    }
  }
  .loyalty__panel__points__button {
    .view-all {
      position: absolute;
      bottom: 20px;
    }
  }
}

.form-item.loyalty {
  .loyalty_terms_condition {
    label {
      font-family: $base-font-family;
    }
  }
}

#loyalty-status__content {
  #loyalty__page__status {
    #loyalty__panel__offers__sku {
      li.offer_not_met {
        .loyalty__panel__offers__sku__cta {
          a.not_met {
            background-color: $color-btn-gray;
          }
        }
        .loyalty__panel__offers__sku__product__image {
          opacity: 0.5;
        }
      }
    }
  }
}
/* Checkout page */
.offer-section {
  .offer-code-panel {
    .checkout__panel__title {
      @media #{$medium-up} {
        font-size: 22px;
        padding-bottom: 16px;
        cursor: pointer;
      }
    }
  }
}

.checkout__offer-code__wallet {
  visibility: hidden; //hide pre slick carousel
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
  @media #{$medium-up} {
    height: 430px;
    margin-bottom: 30px;
  }
  &.offer_applied {
    &.slick-slider {
      .checkout__offer-code__wallet-item {
        &.automatic {
          .checkout__offer-code__wallet-apply {
            display: inline-block;
            border: 1px solid $color-shade-grey;
            background: $color-white;
            color: $color-shade-grey;
          }
        }
        .checkout__offer-code__wallet-apply {
          display: none;
        }
      }
    }
    .checkout__offer-code__wallet-item {
      .checkout__offer-code__wallet-apply {
        background: $color-light-gray;
        border: 1px solid $color-light-gray;
        color: $color-white;
      }
    }
  }
  .slick-dots {
    display: none !important;
    @media #{$medium-up} {
      display: block;
      top: 200px;
      bottom: auto;
    }
  }
  .slick-prev {
    background-image: url(/media/export/cms/nav-arrows.png);
    background-repeat: no-repeat;
    height: 55px;
    @include swap_direction(margin, -27px 0 0 0);
    opacity: 0.3;
    position: absolute;
    text-indent: -9999px;
    top: 35%;
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
    width: 27px;
    background-position: left 10px;
    #{$ldirection}: 10px;
    z-index: 100;
    @media #{$medium-up} {
      #{$ldirection}: 5px;
    }
  }
  .slick-next {
    background-image: url(/media/export/cms/nav-arrows.png);
    background-repeat: no-repeat;
    height: 55px;
    @include swap_direction(margin, -27px 0 0 0);
    opacity: 0.3;
    position: absolute;
    text-indent: -9999px;
    top: 35%;
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
    width: 27px;
    background-position: right 10px;
    #{$rdirection}: 10px;
    text-align: left;
    z-index: 100;
    @media #{$medium-up} {
      #{$rdirection}: 5px;
    }
  }
  .checkout__offer-code__wallet {
    &-item {
      text-align: center;
      font-size: 21px;
      @media #{$medium-up} {
        font-size: 22px;
      }
      &__image-container {
        padding: 0;
        margin-bottom: 16px;
        @media #{$medium-up} {
          margin-bottom: 35px;
        }
      }
      &__inner {
        float: none;
        width: auto;
        padding: 0;
      }
      &__image {
        display: none;
        width: 35%;
        background: $color-white;
        @media #{$medium-up} {
          width: 250px;
          height: 250px;
          background: $color-white;
        }
        @include breakpoint($ab-small-down) {
          width: 60%;
        }
        &__title {
          color: $color-black;
          font-size: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 180px;
          line-height: 1.1;
          letter-spacing: 0.02em;
        }
      }
      &__image_clinique {
        display: inline-block !important;
      }
      &__header {
        font-size: 21px;
        @media #{$medium-up} {
          font-size: 22px;
        }
      }
      &__description {
        font-size: 15px;
        line-height: 1.6;
        @media #{$medium-up} {
          font-size: 16px;
          line-height: 2;
        }
      }
      &__button-container {
        position: relative;
        margin-top: 20px;
        @media #{$medium-up} {
          text-align: center;
        }
      }
      &.is-applied {
        .checkout__offer-code__wallet {
          &-apply {
            display: none;
          }
          &-remove {
            display: inline-block;
          }
          &-selected {
            display: inline-block;
          }
          &-item__promo-applied {
            display: block;
            margin-top: 20px;
            font-size: 12px;
          }
        }
      }
      &__button-loader {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background: url(/media/images/account/loyalty/ajax-loader.gif) no-repeat center center
          $color-white;
        z-index: 10;
        @media #{$medium-up} {
          background-color: $color-white;
        }
      }
      &__promo-applied {
        display: none;
      }
      &__link-container {
        margin-top: 20px;
      }
      &__link {
        font-size: 12px;
        font-weight: bold;
        line-height: 0.44;
        text-transform: uppercase;
        text-decoration: underline;
      }
      .loyalty__panel__offers__offer-code-wrap {
        @media #{$medium-up} {
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 0.15em;
          margin: 6px 0;
          .loyalty__panel__offers__offer-code {
            font-weight: bold;
          }
        }
      }
    }
    &-apply,
    &-remove {
      padding: 20px 40px;
      background: $color-black;
      color: $color-white;
      font-size: 15px;
      font-weight: bold;
    }
    &-remove {
      display: none;
    }
    &-selected {
      display: none;
      background: $color-shade-grey;
      color: $color-white;
      pointer-events: none;
    }
    &-apply {
      height: 33px;
      line-height: 2.8;
    }
  }
  &-count {
    @include swap_direction(padding-bottom, 15px);
    text-align: center;
  }
}

#viewcart {
  .loyalty_points_checkout_offers {
    text-align: center;
  }
}

#signin {
  #main {
    .signin_container {
      .sign-in {
        #signin-block {
          .inner-block {
            #new-account {
              .login-email {
                form#registration_short {
                  .form-item.email_promotions.phone2.container {
                    span {
                      width: 45%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#landing.is-loyalty-member {
  .account_landing_wrapper {
    .account.breadcrumb.panel {
      padding-bottom: 930px;
    }
    .best-sellers-loyalty-wrapper {
      .prod-shelf-row {
        .btn-shopnow {
          font-size: 1em;
          padding: 4px 10px 3px;
          text-transform: capitalize;
          width: auto;
        }
      }
    }
  }
}

.loyalty_mrkt_ldng {
  #loyalty_market_sticky_nav {
    .loyality_sign_off {
      @include swap_direction(padding, 20px 0 0 8px);
    }
    .loyalty_join_now,
    .loyalty_signin {
      width: 50%;
      button.btn_sign_in {
        @media #{$small-only} {
          padding: 14px 0;
          width: 100%;
          text-align: center;
        }
      }
      .btn_join {
        display: block;
        margin: 15px 0 0;
        font-size: 18px;
        background: $color-black;
        border: 0;
        padding: 14px 50px;
        color: $color-white;
        font-weight: bold;
        @media #{$small-only} {
          padding: 14px 0;
          border: 2px solid $color-black;
          width: 100%;
          text-align: center;
        }
      }
      @media #{$small-only} {
        @include swap_direction(padding, 0 0 0 10px);
        width: 48%;
      }
    }
  }
}

.account-header-panel {
  ul {
    &.shortcuts {
      @include swap_direction(padding, 35px 0 20px 10px);
    }
  }
  li {
    a {
      img {
        @media #{$small-only} {
          float: #{$ldirection};
          clear: #{$ldirection};
        }
      }
    }
  }
}

#main {
  .signin_container {
    #signin-block {
      .inner-block {
        .loyalty__block {
          @media #{$ipad-portrait-only} {
            @include swap_direction(padding, 0 0 0 7%);
          }
          h3 {
            &.loyalty__make {
              @media #{$ipad-portrait-only} {
                font-size: 17px;
              }
            }
          }
          span {
            &.loyalty__text {
              @media #{$ipad-portrait-only} {
                font-size: 14px;
              }
            }
          }
          .loyalty__subdivision {
            .loyalty__subdivision__right_block {
              .loyal_image {
                @media #{$ipad-portrait-only} {
                  width: 160px;
                }
              }
            }
          }
        }
      }
    }
  }
}

#node-360823 {
  .split-width-text-tout__body.content-block__line {
    @media #{$medium-up} {
      min-width: max-content;
    }
  }
}

.loyalty-join-now-terms {
  display: flex;
  align-items: center;
  #form--loyalty_join--field--ACCEPTED_LOYALTY_TERMS {
    margin-right: 10px;
    width: 50px;
  }
  input[type='checkbox'] {
    &:focus {
      outline: none !important;
    }
  }
}
.button--disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
  pointer-events: none;
}

#guest-user {
  @include breakpoint($ab-small-down) {
    padding: 1em;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
