/** Tooltip **/
.tooltip {
  position: relative;
  display: inline-block;
  width: 100%;
  font-weight: bold;
  a {
    color: $color-white;
    text-decoration: underline;
  }
  &:hover {
    .tooltiptext-under,
    .tooltiptext-over {
      visibility: visible;
      opacity: 1;
    }
  }
  .tooltiptext-under,
  .tooltiptext-over {
    visibility: hidden;
    width: 100%;
    background-color: $color-cl-davy-grey;
    color: $color-white;
    text-align: center;
    border-radius: 6px;
    padding: 10px 5px;
    position: absolute;
    z-index: 1;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;
    font-weight: normal;
  }
  .tooltiptext-under {
    top: 20px;
    &:after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 10%;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $color-cl-davy-grey transparent;
    }
  }
  .tooltiptext-over {
    bottom: 20px;
    &:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 10%;
      border-width: 5px;
      border-style: solid;
      border-color: $color-cl-davy-grey transparent transparent transparent;
    }
  }
}
