.click-and-collect-method-select {
  float: left;
  margin-bottom: 20px;
  margin-top: 15px;
  @include breakpoint($medium-l) {
    margin-bottom: 20px;
    margin-top: auto;
  }
  .radio-button {
    float: left;
    width: 66%;
    @include breakpoint($medium-l) {
      width: 100%;
    }
    input {
      float: left;
    }
  }
  .auspost-logo {
    float: right;
    width: 33%;
    @include breakpoint($medium-l) {
      display: none;
    }
  }
  label {
    text-transform: none;
    float: left;
    @include breakpoint($medium-l) {
      max-width: 90%;
    }
    span {
      display: inline-block;
    }
    &::before {
      float: left;
      margin-top: 3px;
    }
  }
  .auspost-label {
    font-weight: bold;
    max-width: 400px;
    float: left;
    @include breakpoint($medium-l) {
      width: 100%;
    }
  }
  .auspost-note {
    display: inline-block;
    margin-left: 22px;
    margin-top: 10px;
    @include breakpoint($medium-l) {
      width: 100%;
    }
  }
  .auspost-overlay.button {
    margin: 15px;
  }
  .auspost-address-display {
    p {
      margin: 0;
    }
  }
}

#colorbox.auspost-overlay {
  #cboxContent {
    -webkit-overflow-scrolling: touch;
  }
  #auspost-widget {
    padding-top: 20px;
  }
  .auspost-continue {
    width: 100%;
    .location-selected {
      margin: 20px auto;
      display: block;
      width: 150px;
    }
  }
  #cboxClose {
    top: 2px;
    right: 9px;
    &:before {
      font-size: 22px;
    }
    @include breakpoint($medium-l) {
      display: none;
      right: 0;
      top: 0;
    }
  }
}
