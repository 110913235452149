#customer_service_chat {
  margin-top: 50px;
  a {
    margin-top: 60px;
    display: block;
  }
}

.cs-3-col-row-v1 {
  .customer-care-list {
    .customer-care-list-item {
      .body-content {
        max-height: 200px;
      }
    }
  }
}

#findation-widget-header {
  z-index: -1;
  width: 400px;
}

#findation-container-mobile {
  background-size: contain;
  height: 100px;
  min-height: 110px;
  position: relative;
  width: 100%;
  #findation-button-iframe {
    position: relative;
    top: 50px;
  }
}

#findation-widget-button {
  position: relative;
}

#findation-container {
  height: 100px;
  min-height: 110px;
  position: relative;
  width: 400px;
  #findation-button-iframe {
    bottom: 10px;
    position: absolute;
  }
  .button-inner {
    img {
      height: auto;
      width: 150px;
    }
  }
}

#navigation {
  .outer-wrap {
    .navigation-right {
      float: none;
    }
    @include breakpoint-range($medium-ipad-p, $medium-max) {
      max-width: $ipad-header-wrapper_width;
    }
  }
  .mm-formatter-v1 {
    ul.mm-menu {
      padding: 0;
      @include breakpoint-range($medium-ipad-p, $medium-max) {
        padding: 0 0 0 20px;
      }
      li {
        span.title {
          padding: 0 5px;
          font-size: 14px;
          @include breakpoint-range($medium-ipad-p, $medium-max) {
            padding: 0 10px;
          }
        }
      }
    }
  }
}
