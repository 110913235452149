.optanon-show-settings-popup-wrapper {
  .optanon-show-settings-button {
    .optanon-show-settings-middle {
      .optanon-show-settings {
        font-size: 12px;
        @media #{$cr19-medium-up} {
          font-size: 15px;
        }
      }
    }
  }
}

#ot-sdk-btn {
  &.ot-sdk-show-settings {
    font-size: 12px !important;
    font-family: $base-bold-font-family;
    @media #{$cr19-medium-up} {
      font-size: 15px !important;
    }
  }
}
